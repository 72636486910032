import React, { useMemo } from 'react'
import { useDisplaySysAdmin } from '../../../../../../role/role.component'
import { ROLE_ORG } from '../../../../../../role/role.const'
import { useRoleAccessOrganization } from '../../../../../../role/role.hook'
import { IconFile, IconGroup, IconMove, IconUser } from '../../../../../../shared/icon/icon'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuItem,
  MenuItemHeader,
  MenuRecycle
} from '../../../../../../shared/menu/menu'

export const NodeCategoryMenuFull = (url, parentID, showCategoryAdd) => {
  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const displaySysAdmin = useDisplaySysAdmin()

  const hasNode = useMemo(() => !!parentID, [parentID])

  return (
    <React.Fragment>
      <Menu>
        <MenuItemHeader text="sys admin" show={displaySysAdmin} />
        <MenuEdit text="sys edit" to={`${url}/edit-sys`} show={displaySysAdmin} pin={false} />
        <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={displaySysAdmin} />
        <MenuAdd Icon={IconFile} text="add node" to={`${url}/add-type`} show={displaySysAdmin} pin={false} />

        <MenuItemHeader text="org" show={displaySysAdmin} />
        {showCategoryAdd && <MenuAdd text="add category" to={`${url}/add`} show={roleContent.accessCreate} pin={false} />}
        {hasNode && <MenuEdit to={`${url}/edit`} show={roleContent.accessUpdate} pin={false} />}
        {hasNode && <MenuItem text="move" Icon={IconMove} to={`${url}/move`} show={roleContent.accessUpdate} />}
        {hasNode && <MenuDelete to={`${url}/delete`} show={roleContent.accessDelete} />}

        {hasNode && <MenuItem text="user permissions" Icon={IconUser} to={`${url}/user-permission`} show />}
        {hasNode && <MenuItem text="group permissions" Icon={IconGroup} to={`${url}/group-permission`} show />}

        <MenuRecycle to={`${url}/recycle`} show={roleContent.accessDelete} />
        <MenuActivityLog to={`${url}/activity`} show />
      </Menu>
    </React.Fragment>
  )
}

export const NodeCategoryMenuPartial = url => {
  const roleContent = useRoleAccessOrganization(ROLE_ORG.CONTENT)
  const displaySysAdmin = useDisplaySysAdmin()

  return (
    <Menu>
      <MenuItemHeader text="sys admin" show={displaySysAdmin} />
      <MenuEdit text="sys edit" to={`${url}/edit-sys`} show={displaySysAdmin} pin={false} />
      <MenuItem Icon={IconFile} text="assets" to={`${url}/edit-sys/assets`} show={displaySysAdmin} />

      <MenuItemHeader text="org" show={displaySysAdmin} />
      <MenuEdit to={`${url}/edit`} show={roleContent.accessUpdate} pin={false} />
      <MenuItem text="move" Icon={IconMove} to={`${url}/move`} show={roleContent.accessUpdate} />
      <MenuDelete to={`${url}/delete`} show={roleContent.accessDelete} />
    </Menu>
  )
}
